import React, {useState} from 'react';
import Accounts from "./Currency Accounts/currencyAccounts";
import AccountStatement from "./Account Statement/AccountStatement";

const Currency = () => {
    const [activeTab, setActiveTab] = useState('currency-accounts'); // Initially set to 'bankTransfer'

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='currency-container'>
            <div className='currency-tabs-box'>
                <button
                    onClick={() => handleTabChange('currency-accounts')}
                    className={`currency-tab ${activeTab === 'currency-accounts' ? 'active' : ''}`}
                >
                    Обзор счетов
                </button>
                <button
                    onClick={() => handleTabChange('currency-statement')}
                    className={`currency-tab ${activeTab === 'currency-statement' ? 'active' : ''}`}
                >
                    Выписка по счету
                </button>
            </div>
            <div>
                {activeTab === 'currency-accounts' && <Accounts />}
                {activeTab === 'currency-statement' && <AccountStatement/> }
            </div>
        </div>
    );
};

export default Currency;
