import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Planet from '../Assets/planet.svg';
import MyBtn from '../ui/MyBtn/MyBtn';
import './Home.css';
import MyInput from "../ui/MyInput/MyInput";
import {useDispatch} from "react-redux";
import {sendContactFormThunk} from "../../store/reducers/actions";
import CustomModal from "../ui/customModal/customModal";

const Home = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: ""
    });

    const [errors, setErrors] = useState({
        email: "",
        phone: ""
    });

    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const validatePhone = (phone) => {
        const phoneRegex = /^\+?[1-9]\d{1,14}$/; // International phone number regex (E.164 format)
        return phoneRegex.test(phone);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex
        return emailRegex.test(email);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleBlur = (e) => {
        const { name, value } = e.target;

        if (name === "phone") {
            if (!validatePhone(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: "Пожалуйста, введите номер телефона (например, +123456789).",
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    phone: "",
                }));
            }
        }

        if (name === "email") {
            if (!validateEmail(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "Пожалуйста, введите действительный адрес электронной почты.",
                }));
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "",
                }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Final validation check before submitting
        if (errors.phone || errors.email || !validatePhone(formData.phone) || !validateEmail(formData.email)) {
            alert("Пожалуйста, исправьте ошибки перед отправкой.");
            return;
        }

        try {
            await dispatch(sendContactFormThunk(formData));
            setIsPopupOpen(true);
        } catch (error) {
            alert("Произошла ошибка. Попробуйте еще раз.");
        }
    };

    return (
      <section className="section">
        <div className="home-container container grid ">
          <div className="primary-home-content grid">
            <div className="home-details">
              <h1 className="section-title">БАНК ДЛЯ МЕНЯЮЩЕГОСЯ МИРА</h1>
              <p className="home-description">
                Мы поддерживаем экономический и социальный рост резидентов стран СНГ, способствуя их успешному выходу
                на международные рынки. Благодаря высококачественным банковским услугам и современным технологиям,
                мы помогаем нашим клиентам эффективно развивать и укреплять свой бизнес.
              </p>
              <div className="btn-block">
                <MyBtn title="Подробнее" large onClick={() => navigate('/aboutBank')} />
              </div>
            </div>

            <div className="home-img-container">
              <img src={Planet} alt="Planet" className="home-img" />
            </div>
          </div>

          <div className="quote-container">
            <p className="quote-text">
            «Международная СберКасса — это надежный инструмент для уверенного продвижения вашего бизнеса к успеху.»
            </p>
          </div>

          <div className="text-section">
            <div className="text-block">
              <div className="section-title">Банк сегодня</div>
              <div className="finance-content">
                Международная СберКасса осуществляет операции с Мая 2022 года. Мы обслуживаем индивидуальных и
                корпоративных клиентов различных форм собственности и направлений деятельности.
              </div>
              <div className="btn-block">
                <MyBtn title="О Банке" large onClick={() => navigate('/aboutBank')} />
              </div>
            </div>
          </div>

          <div className="benefit-container">
            <div className="benefit-row">
              <div className="benefit-no">01</div>
              <div className="benefit-text">
                Развитие бизнеса Банка строится на предоставлении клиентам широкого спектра высокотехнологических
                банковских продуктов и услуг на уровне международных стандартов.
              </div>
            </div>

            <div className="benefit-row">
              <div className="benefit-no">02</div>
              <div className="benefit-text">
                Банк «Международная СберКасса» готов предоставить гарантию успешных платежей для русскоязычных Клиентов,
                вне зависимости от политических границ.
              </div>
            </div>

            <div className="benefit-row">
              <div className="benefit-no">03</div>
              <div className="benefit-text">
                Широкий список Банков - Корреспондентов позволяет нам осуществлять переводы любой сложности в любую
                точку мира.
              </div>
            </div>

            <div className="benefit-row">
              <div className="benefit-no">04</div>
              <div className="benefit-text">
                Вместе с Международной СберКассой вы сможете с уверенностью совершать сделки, инвестировать в
                недвижимость, а также приумножать свой капитал.
              </div>
            </div>
          </div>

          <div className="home-card-section">
            <div className="cards-text">
              <h1 className="section-title-center">Наши эксклюзивные преимущества</h1>
              <div className="cards-info">
                «Откройте новые горизонты финансов с нашим эксклюзивным пакетом преимуществ, который обеспечит вам удобство и безопасность. Воспользуйтесь нашими возможностями для достижения уверенности и спокойствия.»
              </div>
            </div>
            <div className="cards-container">
              <div className="card">
                <div className="card-title">Международная ориентация</div>
                <div className="card-content">
                «Способствуем экономическому и социальному развитию резидентов стран СНГ на международном рынке, предлагая доступ к современным банковским услугам.»
                </div>
              </div>

              <div className="card">
                <div className="card-title">Широкий спектр услуг</div>
                <div className="card-content">
                «Предлагаем клиентам разнообразные банковские услуги высокого качества, включая услуги по
                  финансированию, инвестированию, и обслуживанию счетов.»
                </div>
              </div>

              <div className="card">
                <div className="card-title">Инновационные технологии</div>
                <div className="card-content">
                «Используем современные банковские технологии, чтобы обеспечить удобство и безопасность для наших клиентов.»
                </div>
              </div>

              <div className="card">
                <div className="card-title">Безопасность и Надежность</div>
                <div className="card-content">
                «Осуществляем банковские операции с высоким уровнем безопасности и надежности, защищая интересы наших
                  клиентов.»
                </div>
              </div>
            </div>
            <div className="cards-btn">
              <MyBtn title="Открыть Счет" large onClick={() => navigate('/register')} />
            </div>
          </div>

          {/* <div className="quote-container">
            <div className="quote-text">
              "Мы с уважением относимся к каждому резиденту <br /> нашей Земли."
            </div>
          </div> */}

          <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
            <div className="title">Успешно!</div>
            <div className="modal-section">Ваша заявка была отправлена.</div>
            <div className="modal-section">
              <MyBtn
                medium
                title={'Ok'}
                onClick={() => {
                  setIsPopupOpen(false);
                }}
              />
            </div>
          </CustomModal>

          <div className="home-contact-container">
            <p className="home-contact-title">
            Воспользуйтесь бесплатной <b>консультацией</b> специалиста
            </p>

            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="input-block">
                <div className="label">ФИО контактного лица</div>
                <MyInput
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Виктория Давлатова"
                />
              </div>
              <div className="input-block">
                <div className="label">E-MAIL</div>
                <MyInput
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="bub@mail.ru"
                />
                {errors.email && <p className="home-contact-error">{errors.email}</p>}
              </div>
              <div className="input-block">
                <div className="label">Телефон</div>
                <MyInput
                  id="phone"
                  name="phone"
                  placeholder="Контактный номер телефона"
                  value={formData.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.phone && <p className="home-contact-error">{errors.phone}</p>}
              </div>

              <MyBtn title="Отправить" large style={{ width: '420px' }} type="submit" />
            </form>
          </div>
        </div>
      </section>
    );
};

export default Home;

