export const handleDownloadCSV = (transactions) => {
    if (!transactions || transactions.length === 0) {
        alert("Нет данных для загрузки.");
        return;
    }

    const headers = [
        "Дата",
        "Перевод №",
        "Отправитель",
        "Получатель",
        "Тип",
        "Назначение",
        "направление",
        "Сумма",
        "Баланс",
        "статус",
    ];

    const rows = transactions.map((transaction) => [
        new Date(transaction.created * 1000).toLocaleDateString("ru-RU"),
        transaction.transactionNumber,
        transaction.fromAccount,
        transaction.toAccount,
        transaction.transactionType,
        transaction.reference,
        transaction.direction === "Входящий"
            ? `+${transaction.amount} ${transaction.currency}`
            : `-${transaction.amount} ${transaction.currency}`,
        transaction.direction,
        `${transaction.balance} ${transaction.currency}`,
        transaction.transactionStatus,
    ]);

    const csvContent =
        [headers, ...rows]
            .map((row) => row.map((cell) => `"${cell}"`).join(","))
            .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "выписка из банка.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
